import {Component, Input, Output, ViewEncapsulation, EventEmitter, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'core-touchspin',
  templateUrl: './core-touchspin.component.html',
  styleUrls: ['./core-touchspin.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreTouchspinComponent),
      multi: true
    }
  ]
})
export class CoreTouchspinComponent {

  @Input('numberValue') numberValue: number = 0;
  @Input('iconChevron') iconChevron: boolean = false;
  @Input('disable') disabledValue: boolean = false;
  @Input('size') size: string = '';
  @Input('color') color: string = '';
  @Input('stepPlus') stepPlus: number | undefined
  @Input('stepMinus') stepMinus: number | undefined
  @Input('maxValue') maxValue: number = Infinity;
  @Input('minValue') minValue: number = -Infinity;
  @Output() counterChange: EventEmitter<number> = new EventEmitter<number>()

  private _defaultStepPlus: number = 0.5
  private _defaultStepMinus: number = 0.5

  constructor() {}

  // accessor methods -->
  onChange: any = () => {}
  onTouched: any = () => {}

  writeValue(value: number): void {
    this.numberValue = value
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState?(isDisabled: boolean): void {}

  public increment(): void {
    if (this.numberValue < this.maxValue) {
      this.numberValue += this.stepPlus || this._defaultStepPlus
      if (this.numberValue > this.maxValue) {
        this.numberValue = this.maxValue
      }
      this.onChange(this.numberValue)
      this.counterChange.emit(this.numberValue)
    }
  }

  public decrement(): void {
    if (this.numberValue > this.minValue) {
      this.numberValue -= this.stepMinus || this._defaultStepMinus
      if (this.numberValue < this.minValue) {
        this.numberValue = this.minValue
      }
      this.onChange(this.numberValue)
      this.counterChange.emit(this.numberValue)
    }
  }

  public onInput(event: any): void {
    const value: number = +event?.target?.value
    if (!value) return
    this.onChange(this.numberValue)
    this.counterChange.emit(this.numberValue)
  }

  public onFocusOut() {
    let newValue: number = this.numberValue
    if (newValue < this.minValue) {
      newValue = this.minValue
    } else if (newValue > this.maxValue) {
      newValue = this.maxValue
    }
    this.numberValue = newValue
    this.onChange(this.numberValue)
    this.counterChange.emit(this.numberValue)
  }

}
