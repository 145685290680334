import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {ManagerService} from "../services/manager.service";
import {Observable, of} from "rxjs";
import {tap} from "rxjs/operators";
import {
    ManagerUsersResponseInterface
} from "../../modules/general/users/users-view/common/summary/core/interfaces/manager-users-response.interface";
import {IUser} from "../../modules/general/users/core/interfaces/IUser";

@Injectable({
    providedIn: 'root'
})
export class ManagerUsersResolver implements  Resolve<ManagerUsersResponseInterface> {

    constructor(
        private _managerService: ManagerService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ManagerUsersResponseInterface> | Promise<ManagerUsersResponseInterface> | ManagerUsersResponseInterface {
        const prevUsers: number[] = JSON.parse(localStorage.getItem('currentUser'))?.manager_users_ids

        if (!prevUsers) {
            return this._managerService.getManagerUsers().pipe(
                tap((response: ManagerUsersResponseInterface) => {
                    const managersIds: number[] = response.results.map((manager: IUser) => manager.id);
                    localStorage.setItem('currentUser', JSON.stringify({
                        ...JSON.parse(localStorage.getItem('currentUser')),
                        manager_users_ids: managersIds
                    }))
                })
            );
        }
    }

}