import {Injectable} from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import {Observable} from "rxjs";
import {ToastrService} from "ngx-toastr";

@Injectable({
    providedIn: 'root'
})
export class ResetGuard implements CanActivate, CanActivateChild {

    protected readonly resetKey: string = 'reset=true'

    constructor(
        private _toastrService: ToastrService
    ) {}

    private checkResetUrl(state: RouterStateSnapshot): boolean {
        const isReset: boolean = state.url.includes(this.resetKey)
        if (isReset) {
            localStorage.clear()
            this._toastrService.success('Локальное хранилище успешно очищено')
            setTimeout(() => {
                window.location.href = state.url.replace(this.resetKey, '')
            }, 2000)
        }
        return !isReset
    }

    canActivate(
        route: ActivatedRouteSnapshot, state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkResetUrl(state)
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkResetUrl(state)
    }

}