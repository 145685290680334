import { Routes } from "@angular/router";
import { AuthGuard } from "./modules/auth/core/guards/auth.guard";
import { NoAuthGuard } from "./modules/auth/core/guards/no-auth.guard";
import {ResetGuard} from "./shared/guards/reset.guard";
import {ManagerUsersResolver} from "./shared/resolvers/manager-users.resolver";

export const appRouting: Routes = [
	{
		path: '',
		redirectTo: 'auth/login',
		pathMatch: 'full'
	},
	{
		path: 'auth',
		loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
		canActivate: [NoAuthGuard, ResetGuard],
		canActivateChild: [ResetGuard]
	},
	{
		path: 'general',
		loadChildren: () => import('./modules/general/general.module').then(m => m.GeneralModule),
		canActivate: [AuthGuard, ResetGuard],
		canActivateChild: [ResetGuard],
		resolve: {
			managers: ManagerUsersResolver
		},
	},
	{
		path: 'moderation-kyc',
		loadChildren: () => import('./modules/moderation-kyc/moderation-kyc.module').then(m => m.ModerationKycModule),
		canActivate: [AuthGuard, ResetGuard],
		canActivateChild: [ResetGuard],
		resolve: {
			managers: ManagerUsersResolver
		},
	},
	{
		path: 'tariffs',
		loadChildren: () => import('./modules/tariffs/tariffs.module').then(m => m.TariffsModule),
		canActivate: [AuthGuard, ResetGuard],
		canActivateChild: [ResetGuard]
	},
	{
		path: 'settings',
		loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
		canActivate: [AuthGuard, ResetGuard],
		canActivateChild: [ResetGuard]
	},
	{
		path: 'other',
		loadChildren: () => import('./modules/other/other.module').then(m => m.OtherModule),
		canActivate: [AuthGuard, ResetGuard],
		canActivateChild: [ResetGuard]
	},
	{
		path: 'errors',
		loadChildren: () => import('./modules/errors/errors.module').then(m => m.ErrorsModule),
		canActivate: [ResetGuard],
		canActivateChild: [ResetGuard]
	},
	{
		path: '**',
		redirectTo: '/errors/404'
	}
]
