import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {IManager} from "../../modules/general/users/users-view/common/summary/core/interfaces/IManager";
import {map} from "rxjs/operators";
import {
    ManagerUsersResponseInterface
} from "../../modules/general/users/users-view/common/summary/core/interfaces/manager-users-response.interface";
import {IUser} from "../../modules/general/users/core/interfaces/IUser";

@Injectable({
    providedIn: 'root'
})
export class ManagerService {

    constructor(
        private _http: HttpClient
    ) {}

    public getManagerUsers(): Observable<ManagerUsersResponseInterface> {
        const params: HttpParams = new HttpParams()
            .append('page', 1)
            .append('page_size', 10000)

        return this._http.get<ManagerUsersResponseInterface>(`${environment.apiUrl}/manager/manager_users/`, { params })
            .pipe(
                map((response: ManagerUsersResponseInterface) => {
                  return {
                      ...response,
                      results: response.results.map((manager: IUser) => {
                          return {
                              ...manager,
                              full_name: `${manager?.last_name} ${manager?.first_name}`
                          }
                      })
                  }
                })
            )
    }

    public getManagers(): Observable<IManager[]> {
        return this._http.get<IManager[]>(`${environment.apiUrl}/manager/managers/`)
            .pipe(
                map((response: IManager[]) => {
                    return response.map((manager: IManager) => {
                        return {
                            ...manager,
                            full_name: `${manager?.last_name} ${manager?.first_name}`
                        }
                    })
                })
            )
    }

}