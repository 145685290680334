<div class="btn-group touchspin-wrapper" [ngClass]="{ 'touchspin-sm': size == 'sm', 'touchspin-lg': size == 'lg' }">
  <button
    class="btn btn-sm btn-primary btn-touchspin btn-touchspin-down"
    (click)="decrement()"
    [ngClass]="{
      'btn-info': color == 'info',
      'btn-warning': color == 'warning',
      'btn-success': color == 'success',
      'btn-danger': color == 'danger'
    }"
  >
    <span *ngIf="!iconChevron" data-feather="minus"></span>
    <span *ngIf="iconChevron" data-feather="chevron-down"></span>
  </button>
  <input
    type="number"
    class="form-control"
    [(ngModel)]="numberValue"
    [disabled]="disabledValue"
    (input)="onInput($event)"
    (focusout)="onFocusOut()"
  />
  <button
    class="btn btn-sm btn-primary btn-touchspin btn-touchspin-up"
    (click)="increment()"
    [ngClass]="{
      'btn-info': color == 'info',
      'btn-warning': color == 'warning',
      'btn-success': color == 'success',
      'btn-danger': color == 'danger'
    }"
  >
    <span *ngIf="!iconChevron" data-feather="plus"></span>
    <span *ngIf="iconChevron" data-feather="chevron-up"></span>
  </button>
</div>
